<template>
  <div class="input-container" :class="invalid">
    <div
      class="wrapper"
      :class="{
        error,
        'is-right': right,
        'is-left': left,
        'background-grey': backgroundGrey,
      }"
    >
      <input
        :id="id"
        class="input"
        :data-id="DataIdAtom.ATOM_INPUT"
        :value="value"
        :type="type || 'text'"
        :name="name"
        :min="min"
        :max="max"
        :inputmode="inputmode"
        :placeholder="placeholder"
        :disabled="disabled"
        :autocomplete="autocomplete"
        @input="handleInputEvent(Emits.INPUT, $event.target)"
        @focus="emits(Emits.FOCUS)"
        @blur="handleInputEvent(Emits.INPUT, $event.target)"
        @keyup.enter.prevent="handleInputEvent(Emits.ENTER, $event.target)"
      />
      <atom-load-spinner v-if="loading" inner class="spinner" />
      <div
        v-else-if="appendIcon"
        class="append-icon-wrapper d-flex align-items-center justify-content-center"
        :class="{
          'text-states-green-dark': appendIcon.state === Status.SUCCESS,
          'text-base-red': appendIcon.state === Status.ERROR,
        }"
      >
        <component :is="appendIcon.component" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { Emits, Status } from '~common/enums'
import { DataIdAtom } from '~common/enums/tests.ts'
import type { AtomTextInputEmits, AtomTextInputProps } from '~common/types'
import AtomLoadSpinner from '~components/atoms/AtomLoadSpinner.vue'

defineProps<AtomTextInputProps>()
const emits = defineEmits<AtomTextInputEmits>()

const handleInputEvent = (
  emitType: Emits.INPUT | Emits.ENTER,
  target: EventTarget | null,
): void => {
  const value: string | null = target && 'value' in target ? (target.value as string) : null
  emits(emitType, value)
}
</script>

<style lang="scss" scoped>
$form-control-padding-y: 15px;
$form-control-padding-x: 24px;
$form-control-font-size: 1rem;
$form-control-font-weight: 500;
$form-control-line-height: 18px;
$form-control-height: 50px;

.input-container {
  width: 100%;
  height: 75px;
  display: inline-block;
}

.input-container:deep(.error-text) {
  display: block;
  text-align: right;
  color: $base-red;
  margin-top: $space-4;

  &.warning {
    color: $states-orange-dark;
  }
}

.wrapper {
  width: 100%;
  display: inline-block;

  /* Corners works only on the wrapper, therefore a border has to be on the wrapper as well */
  border: 1px solid $grey-100-borders;

  &:not(.is-left):not(.is-right) {
    @include addCorners(
      'small',
      'both',
      $grey-100-borders,
      'nogradient',
      'bg-grey',
      $transition: 'off'
    );

    &:focus-within {
      @include form-box-shadow;
      @include addCorners(
        'small',
        'both',
        $grey-200-disable,
        'gradient',
        'bg-grey',
        $transition: 'off'
      );

      border: 1px solid $grey-200-disable;
    }
  }

  &:not(.is-left):not(.is-right):not(.background-grey) {
    @include addCorners('small', 'both', $transition: 'off');

    &:focus-within {
      @include form-box-shadow;
      @include addCorners('small', 'both', $grey-200-disable, 'gradient', $transition: 'off');

      border: 1px solid $grey-200-disable;
    }
  }

  &.is-left {
    @include addCorners(
      'small',
      'left',
      $grey-100-borders,
      'nogradient',
      'bg-grey',
      $transition: 'off'
    );

    &:focus-within {
      @include form-box-shadow;
      @include addCorners(
        'small',
        'left',
        $grey-200-disable,
        'gradient',
        'bg-grey',
        $transition: 'off'
      );

      border: 1px solid $grey-200-disable;
    }
  }

  &.is-left:not(.background-grey) {
    @include addCorners('small', 'left', $transition: 'off');

    &:focus-within {
      @include form-box-shadow;
      @include addCorners('small', 'left', $grey-200-disable, 'gradient', $transition: 'off');

      border: 1px solid $grey-200-disable;
    }
  }

  &.is-right {
    @include addCorners(
      'small',
      'right',
      $grey-100-borders,
      'nogradient',
      'bg-grey',
      $transition: 'off'
    );

    &:focus-within {
      @include form-box-shadow;
      @include addCorners(
        'small',
        'right',
        $grey-200-disable,
        'gradient',
        'bg-grey',
        $transition: 'off'
      );

      border: 1px solid $grey-200-disable;
    }
  }

  &.is-right:not(.background-grey) {
    @include addCorners('small', 'right', $transition: 'off');

    &:focus-within {
      @include form-box-shadow;
      @include addCorners('small', 'right', $grey-200-disable, 'gradient', $transition: 'off');

      border: 1px solid $grey-200-disable;
    }
  }
}

.input {
  border: none;
  width: 100%;
  padding: $form-control-padding-y $form-control-padding-x;
  font-weight: $form-control-font-weight;
  font-size: $form-control-font-size;
  line-height: $form-control-line-height;
  height: $form-control-height;
  color: $base-black;
  background: transparent;

  &:focus-visible {
    outline: 0;
    border: none;
  }

  &::placeholder {
    font-weight: normal;
    color: $grey-200-disable;
  }
}

// This .invalid class generates by vee-validate lib so initially we do not have it on the tempalge
// stylelint-disable rule-empty-line-before
// stylelint-disable no-descending-specificity
// eslint-disable-next-line vue-scoped-css/require-selector-used-inside
.invalid.input-container {
  .wrapper {
    border: 1px solid $base-red;

    &:not(.is-left):not(.is-right) {
      @include addCorners('small', 'both', $base-red, 'nogradient', 'bg-grey', $transition: 'off');

      &:focus-within {
        @include addCorners('small', 'both', $base-red, 'gradient', 'bg-grey', $transition: 'off');

        border: 1px solid $base-red;
      }
    }

    &:not(.is-left):not(.is-right):not(.background-grey) {
      @include addCorners('small', 'both', $base-red, $transition: 'off');

      &:focus-within {
        @include addCorners('small', 'both', $base-red, 'gradient', $transition: 'off');

        border: 1px solid $base-red;
      }
    }

    &.is-left {
      @include addCorners('small', 'left', $base-red, 'nogradient', 'bg-grey', $transition: 'off');

      &:focus-within {
        @include addCorners('small', 'left', $base-red, 'gradient', 'bg-grey', $transition: 'off');

        border: 1px solid $base-red;
      }
    }

    &.is-left:not(.background-grey) {
      @include addCorners('small', 'left', $base-red, $transition: 'off');

      &:focus-within {
        @include addCorners('small', 'left', $base-red, 'gradient', $transition: 'off');

        border: 1px solid $base-red;
      }
    }

    &.is-right {
      @include addCorners('small', 'right', $base-red, 'nogradient', 'bg-grey', $transition: 'off');

      &:focus-within {
        @include addCorners('small', 'right', $base-red, 'gradient', 'bg-grey', $transition: 'off');

        border: 1px solid $base-red;
      }
    }

    &.is-right:not(.background-grey) {
      @include addCorners('small', 'right', $base-red, $transition: 'off');

      &:focus-within {
        @include addCorners('small', 'right', $base-red, 'gradient', $transition: 'off');

        border: 1px solid $base-red;
      }
    }
  }
}

.append-icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: $space-12;
}

.spinner {
  position: absolute;
  top: 15px;
  left: calc(100% - 40px);
}

.spinner:after {
  border: 2px solid $base-red;
  border-color: $base-red transparent $base-red transparent;
}
</style>
